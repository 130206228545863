.card {
  &-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    width: 100%;
  }

  &-wrapper-rows {
    flex-flow: row wrap;
    margin-top: 0;

    & > .card {
      min-width: calc(50% - 16px);
      max-width: calc(50% - 16px);
      margin-top: 32px;
    }
  }

  &.dashboard {
    flex: 1;
  }
}
