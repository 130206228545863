@import "../Input/input";

%floating-box {
  padding: 10px;
  width: auto;
  height: auto;
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 12px 0 rgba(2 2 2 / 11%);
  position: absolute;
  right: unset;
  overflow-y: auto;
  outline: none;
  z-index: 100;

  &::after {
    bottom: 100%;
    right: 10%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255 255 255 / 0%);
    border-bottom-color: #fff;
    border-width: 10px;
  }

  &.secondary {
    background: black;
  }

  &.right {
    right: 0;
  }
}

.dropdown {
  outline: none;
  overflow: visible;
  position: relative;
  width: 100%;

  &.noselect {
    position: unset;
    width: unset;
  }

  &-icon {
    outline: none;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    width: 18px;
    height: 18px;
    background-color: transparent;
    transition: background-color 0.1s linear;

    &:hover {
      background-color: rgba(0 0 0 / 3%);
    }
  }

  &-container {
    @extend %generic-input-style;

    display: flex;
    user-select: none;
    align-items: center;
    position: relative;
    width: 100%;

    &:not([disabled], .disabled) {
      cursor: pointer;
    }

    .chev {
      position: absolute;
      top: 10px;
      right: 16px;
      height: 16px;
    }
  }

  &-content {
    @extend %floating-box;

    &.plain {
      border-radius: 0;
    }
  }
}
