@import "@privacy-request/ui/src/theme/DefaultTheme";

.filter-dropdown {
  .filter-content {
    max-height: 80vh;
    min-width: 300px;
    position: relative;

    .filter-config {
      box-shadow: 3px 0 5px -1px rgba(0 0 0 / 40%);
      height: 100%; /* 100% Full-height */
      width: 85%; /* 0 width - change this with JavaScript */
      position: absolute; /* Stay in place */
      z-index: 1; /* Stay on top */
      top: 0; /* Stay at the top */
      left: -90%;
      background-color: #fff;
      overflow-x: hidden; /* Disable horizontal scroll */
      transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */

      > div {
        height: 100%;
        width: 100%;
      }
    }

    .filter-list {
      margin: 0;
      padding: 0;

      &,
      & li {
        list-style: none;
      }

      li {
        border-bottom: 1px solid #ddd;
        cursor: pointer;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
          background: $BG_GREY;
        }

        svg {
          margin-left: 0.25rem;
          vertical-align: middle;
        }

        svg + span {
          margin-left: 0;
        }

        > div {
          padding: 1rem 1.25rem 1rem 2rem;
        }
      }

      &.compact li > div {
        padding: 0.5rem 1rem;
      }
    }

    &.open {
      > .filter-list {
        background: rgba(0 0 0 / 40%);

        > li {
          border-bottom: 1px solid transparent;
          cursor: auto;
        }

        > li:hover {
          background: transparent;
        }
      }

      .filter-config {
        left: 0;
      }
    }
  }
}

.flex {
  display: flex;

  &.spaced {
    justify-content: space-between;
  }
}
