@use "sass:color";
@import "@privacy-request/ui/src/theme/DefaultTheme";

$SPACING: 1.5rem;
$RADIUS: 10px;

.tree {
  ul {
    margin-left: calc($RADIUS - $SPACING) !important;
    padding-left: 0 !important;
  }

  li {
    display: block;
    position: relative;
    padding-left: calc(2 * $SPACING - $RADIUS - 2px) !important;

    &:last-child {
      border-color: transparent;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: calc($SPACING / -2);
      left: calc($SPACING / 2);
      width: calc($SPACING / 2);
      height: calc($SPACING - 1px);
      border: solid #ddd;
      border-width: 0 0 2px 2px;
    }
  }

  summary {
    display: block;
    cursor: pointer;

    &::marker,
    &::-webkit-details-marker {
      display: none;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 1px dotted #000;
    }

    &::before {
      z-index: 1;

      // background : #696 url('expand-collapse.svg') 0 0;
    }
  }

  details[open] > summary::before {
    background-position: calc(-2 * $RADIUS) 0;
  }
}

.workflow-designer-wrapper {
  display: flex;
  flex-direction: row;

  .custom-toolbox {
    max-width: 500px;
    overflow-y: auto;
    padding: 0.25rem 0.5rem;

    ul,
    li {
      list-style: none;
      line-height: $SPACING;
      margin: 0;
      padding: 0;
    }

    li li:hover {
      background: color.adjust($CTA_COLOR, $lightness: 50%);
    }

    details {
      border-bottom: 1px solid grey;
    }

    summary {
      cursor: pointer;
      display: block;
      padding: 0.5rem;
    }

    .custom-toolbox-item {
      cursor: pointer;
    }
  }

  .sqd-designer-react {
    height: 100%;
    flex-grow: 1;

    .sqd-editor {
      height: 100%;
      overflow-y: auto;
    }

    .sqd-smart-editor {
      min-width: 300px;
      width: auto;
    }

    .step-editor {
      padding: 1rem;
    }
  }
}
