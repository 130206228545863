%card-base {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0 0 0 / 10%);
  box-sizing: border-box;
  margin-bottom: 16px;
  margin-top: 16px;
  padding: 23px 0;
  position: relative;
  width: 100%;
}

.card {
  @extend %card-base;

  & & {
    box-shadow: none;
  }

  &.modal {
    padding-top: 10px;
  }

  &.floating {
    margin-top: 0;
    box-shadow: 0 2px 29px -8px rgba(0 0 0 / 75%);
  }
}
