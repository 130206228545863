.logo {
  width: 96px;
  height: 96px;
  object-fit: contain;
}

td .logo {
  width: 38px;
  height: 38px;
}
