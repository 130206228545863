.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 101;
  padding-bottom: 16px;
  transition: background-color 0.2s linear;
  background: rgba(0 0 0 / 0%);
  pointer-events: "none";

  &.active {
    background: rgba(0 0 0 / 50%);
    pointer-events: "auto";
  }
}
