@import "../../theme/DefaultTheme";

.subtitle {
  font-family: Arial, sans-serif;
  font-weight: 700;
  margin: 0;

  &.primary {
    color: $CTA_COLOR;
  }

  &.mute {
    color: $TEXT_MUTE;
  }

  &.center {
    text-align: center;
  }

  &.small {
    font-size: 0.75rem;
  }

  &.large {
    font-size: 1.25rem;
  }
}
