@import "../../../../../../ui-components/src/theme/DefaultTheme";

.card {
  &-widget {
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    border: solid 2px transparent;
    cursor: pointer;
    width: 31%;
    transition: all 0.3s, box-shadow 0.3s ease-in-out;

    * {
      transition: all 0.3s !important;
      cursor: pointer !important;
    }

    &:hover {
      box-shadow: 0 2px 14px 0 rgba(0 0 0 / 16%);
    }

    &.active {
      border-color: $CTA_COLOR;
    }
  }
}
