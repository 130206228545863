@import "../../theme/DefaultTheme";
@import "../Card/card";

.list {
  &-row {
    @extend %card-base;

    margin-top: 6px;
    box-sizing: border-box;
    padding: 0 16px;
    display: flex;
    cursor: auto;
    align-items: center;
    justify-content: space-around;
    background-color: $WHITE;
    border: 2px solid transparent;
    height: 42px;

    &:nth-child(2) {
      margin-top: 0;
    }

    &.clickable {
      cursor: pointer;
    }

    &.secondary {
      background-color: $BG_GREY;
    }

    &.active {
      border: 2px solid $CTA_COLOR;
    }

    &.plain {
      border: none;
      box-shadow: none;
    }
  }
}
