$CTA_COLOR: #1f6187;
$CTA_COLOR_LIGHT: #1f618785;
$CTA_COLOR_HOVER: #1a5475;
$CTA_COLOR_ALERT: #ff8400;
$CTA_COLOR_ALERT_HOVER: #e67802;
$ERROR_COLOR: rgb(255 0 0 / 50%);
$BLACK: #454543;
$WHITE: #fff;
$WHITE_HOVER: #fdfcfc;
$LIGHT_GREY: #d0d0d0;
$GREY: #e1e0e0;
$GREY_DISABLED: #ececec;
$BG_GREY: #f6f6f6;
$BG_GREY_HOVER: #dedede;
$BTN_HOVER: #1a5475;
$SIDE_BAR_ITEM_HOVER: #1a5475;
$DARKISH_GREEN: #1a5475;
$YELLOW: #fffb44;
$CTA_WARN: #d4ac27;
$CTA_WARN_HOVER: #e3c564;
$CTA_ERROR: #d43b27;
$CTA_SUCCESS: #387192;
$CTA_GREEN: #4caf50;
$TEXT_MUTE: #999;
