/* stylelint-disable no-descending-specificity */

$brand-color: #1f6187;
$caret-size: 6px;
$table-text-color: #454543;
$row-background: white;
$row-hover: #e4f1f9;
$row-stripe: #fafaff;

.table {
  border-collapse: separate;
  border-spacing: 0 3px;
  color: $table-text-color;
  font-family: Arial, sans-serif;
  font-size: 16px;
  width: 100%;

  &.fixed {
    table-layout: fixed;
  }

  thead tr:last-child th {
    border-bottom: 1px solid #ddd;
  }

  td,
  th {
    display: table-cell;
    font-weight: normal;
    overflow: hidden;
    padding: 0 16px;
    text-overflow: ellipsis;

    > * {
      vertical-align: middle;
    }

    &.checkbox {
      cursor: pointer;
      height: 100%;
      align-items: center;

      & path {
        fill: $brand-color;
      }

      &:focus path,
      :hover path {
        fill: $brand-color;
      }
    }

    &.clickable {
      cursor: pointer;
      color: $brand-color;
    }

    // Headers and cells that want to shrink to fit contents
    &.shrink {
      width: fit-content;
      white-space: nowrap;
    }

    &.truncate {
      max-width: 0;
      white-space: nowrap;
    }
  }

  th {
    color: $table-text-color;
    font-weight: bold;
    position: relative;
    text-align: left;

    &.sort-down,
    &.sort-up {
      padding-right: 10px;

      &::after {
        border: 6px solid transparent;
        content: " ";
        height: 48px;
        left: 6px;
        line-height: normal;
        position: relative;
        vertical-align: bottom;
      }
    }

    &.sort-down::after {
      top: 10px;
      border-top-color: $brand-color;
    }

    &.sort-up::after {
      bottom: 14px;
      border-bottom-color: $brand-color;
    }

    .resize-handle {
      display: block;
      position: absolute;
      cursor: col-resize;
      width: 7px;
      right: 0;
      top: 0;
      z-index: 1;
      border-right: 2px double transparent;
    }

    &:hover .resize-handle {
      border-color: #ccc;
    }

    .resize-handle.active {
      border-color: $brand-color;
    }
  }

  td {
    font-size: 16px;
  }

  thead tr {
    height: 48px;
  }

  tbody tr {
    background-color: $row-background;
    height: 48px;

    &:hover:not(.disabled, .nohover),
    &:nth-child(even):hover:not(.disabled, .nohover) {
      background-color: $row-hover;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  tr {
    &.clickable {
      cursor: pointer;
    }
  }

  &.striped tbody tr:nth-child(even) {
    background: $row-stripe;
  }

  &.fixed .shrink {
    width: max-content;
    white-space: nowrap;
  }

  .nowrap {
    white-space: nowrap;
  }

  &.nowrap {
    thead,
    tbody,
    tfoot {
      tr {
        td,
        th {
          white-space: nowrap;
        }
      }
    }

    tr {
      td,
      th {
        white-space: nowrap;
      }
    }

    td,
    th {
      white-space: nowrap;
    }
  }
}

// MISC (MOVE THESE OUT TO A GENERAL STYLESHEET
.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}
