$link-accessible-bg: #1b78b1;
$sky-blue-02: #fafdff;
$sky-blue-60: #66c4ff;
$lemon-yellow-100: #fff170;
$midnight-blue-100: #203546;

.banner {
  background: $sky-blue-60;
  color: $sky-blue-02;

  p {
    background: $sky-blue-60;
    margin: 0;
    padding: 8px;
    text-align: center;
  }

  a {
    color: $sky-blue-02;
  }

  &.yellow {
    background: $lemon-yellow-100;
    color: $midnight-blue-100;

    p {
      background: $lemon-yellow-100;
    }

    a {
      color: $midnight-blue-100;
    }
  }
}
