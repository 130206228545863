@import "../../../../ui-components/src/theme/DefaultTheme";

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.card {
  &.dirtysaverevert {
    display: flex;
    width: unset;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px 24px;
    pointer-events: auto;
    margin: 0;
    border: 1px solid $LIGHT_GREY;
    transform: translate3d(0, 0, 0);
    transition: background-color 0.5s linear, color 0.5s linear;

    & > *:not(:last-child) {
      margin-right: 16px;
    }

    &.shake {
      color: white;
      background-color: $CTA_ERROR;
      transition: background-color 0s linear, color 0s linear;
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }
  }
}
