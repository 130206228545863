.generic-input-style {
  height: 40px;
  outline: none;
  border: solid 1px #e1e0e0;
  box-sizing: border-box;
  transition: border-color 0.3s ease-in-out;
  padding: 11px 16px 12px;
  border-radius: 4px;
  background-color: white;

  &::placeholder {
    color: #454543;
    opacity: 0.5;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    border: solid 1px #454543;
  }

  &[disabled] {
    background-color: #f3f3f3;
  }
}

.generic-text-style {
  margin: 0;
  font-weight: normal;
  color: black;
  font-family: Arial, sans-serif;
  font-size: 16px;
  text-align: left;
}

.generic-nowrap-style {
  overflow: hidden;
  white-space: nowrap;
  padding-right: 20px;
  text-overflow: ellipsis;
}

.dropdown {
  .icon {
    outline: none;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    width: 18px;
    height: 18px;
    background-color: transparent;
    transition: background-color 0.1s linear;

    &:hover {
      background-color: rgba(0 0 0 / 3%);
    }
  }

  .caret {
    position: absolute;
    top: 10px;
    right: 16px;
    height: 16px;
  }

  &-component {
    display: flex;
    user-select: none;
    align-items: center;
    position: relative;
    width: 100%;

    @extend .generic-input-style; /* stylelint-disable-line at-rule-no-unknown */

    &:not([disabled]) {
      cursor: pointer;
    }

    .value {
      @extend .generic-text-style; /* stylelint-disable-line at-rule-no-unknown */
      @extend .generic-nowrap-style; /* stylelint-disable-line at-rule-no-unknown */
    }
  }
}
