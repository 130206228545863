@import "../../theme/DefaultTheme";

@mixin button-style($bg, $bgHover, $color) {
  background: $bg;
  color: $color;

  &:hover {
    background: $bgHover;
  }
}

// Provides styling to <button/>'s and to button-looking <a/>'s
.button {
  font-family: Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
  user-select: none;
  -webkit-tap-highlight-color: rgb(255 255 255 / 0%);
  text-decoration: none;

  // Coloration and shadowing
  background: $CTA_COLOR;
  color: $WHITE;
  box-shadow: 0 4px 18px 0 rgb(28 84 34 / 25%);
  border: none;
  border-radius: 400000px;
  outline: 0;
  cursor: pointer;
  width: 110px; // Seems mighty arbitrary
  height: 44px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: all 0.3s ease-in-out, background 0.3s ease-in-out;

  &:hover,
  &:focus {
    background: $BTN_HOVER;
    border: 1px solid $CTA_COLOR;
    cursor: pointer;
  }

  &.fluid {
    width: 100%;
  }

  &.padded {
    padding: 0 32px;
    width: unset;
  }

  &.short {
    height: 30px; // Seems mighty arbitrary
  }

  &.round {
    border-radius: 50%;
    width: 44px;
  }

  &.plain {
    all: unset;

    &:hover {
      all: unset;
    }
  }

  &.secondary {
    @include button-style(transparent, transparent, $BLACK);

    border: 1px solid $GREY;
    box-shadow: none;

    &:hover {
      border: 1px solid $CTA_COLOR;
    }
  }

  &.nav {
    border-color: $BG_GREY;
    border-radius: 0;
    border-width: 0 1px 0 0;
    color: $CTA_COLOR;
    height: 80px;
    padding: 0 1.5rem;
    width: auto;

    &:first-of-type {
      border-left-width: 1px;
    }
  }

  &.alert {
    @include button-style($CTA_COLOR_ALERT, $CTA_COLOR_ALERT_HOVER, $WHITE);
  }

  &.warn {
    @include button-style($CTA_WARN, $CTA_WARN_HOVER, $WHITE);
  }

  &.error {
    @include button-style($CTA_ERROR, $CTA_ERROR, $WHITE);
  }

  &.disabled,
  &:disabled {
    @include button-style($GREY, $GREY, rgba(0,0,0,0.5));

    border: 1px solid $GREY;
    cursor: auto;
    box-shadow: none;

    &:hover {
      border: 1px solid $GREY;
      cursor: auto;
    }
  }

  // Back button, as seen in the title bar on edit pages and similar
  &.back {
    width: 80px;
    height: 80px;
    position: absolute;
    color: $CTA_COLOR;
    border-color: $BG_GREY;
    border-radius: 0;
  }
}
