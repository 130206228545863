.tag {
  font-family: Arial, sans-serif;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 3px;
  margin-top: 3px;
  margin-right: 5px;
  box-sizing: border-box;
  border: 1px solid transparent;
  background-color: #1f6187;
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: white;
  font-size: 14px;
  line-height: 14px;
  cursor: default;
  transform: background-color 0.1s linear;
  transition:
    background 0.2s ease,
    border-color 0.2s ease,
    color 0.2s ease;

  &.mute {
    background: #eee;
    border-color: #ddd;
    color: #999;

    &.clickable:hover {
      border-color: #1f6187;
      color: #1f6187;
      cursor: pointer;
    }
  }
}
