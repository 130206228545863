.json-editor-wrapper {
  position: relative;

  textarea {
    border: 1px solid #ccc;
    min-height: 200px;
    width: 100%;
  }

  &.invalid {
    textarea {
      border-color: red;
    }
  }
}
